import { AnimatePresence, motion } from "framer-motion";
import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import * as Phosphor from "phosphor-react";
import {
  MouseEventHandler,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import { toPhosphorComponentName } from "../../lib/generic";
// Images
import semIcon from "../../public/assets/brand/sem-icon.svg";
// Components
const LanguageDropdown = dynamic(() => import("./languageDropdown"));

function Navbar({ className, links }: { className?: string; links: any[] }) {
  return (
    <>
      <Desktop className={`${className} invisible lg:visible`} links={links} />
      <Mobile className={`${className} visible lg:invisible`} links={links} />
    </>
  );
}

function Desktop({ className, links }: { className?: string; links: any }) {
  const { pathname } = useRouter();

  return (
    <nav
      className={`${className} fixed top-0 inset-x-0 bg-gray-100 px-6 border-b z-90 dark:border-b-0 dark:bg-gray-700`}
    >
      <div className={`container mx-auto w-full`}>
        <div className={`flex items-stretch justify-between`}>
          {/* Start */}
          <Link href={`/`}>
            <a className={`flex items-center justify-center py-3 space-x-4`}>
              <span className={`relative h-9 w-9`}>
                <Image
                  alt={"SEM Icon"}
                  src={semIcon}
                  layout={"fill"}
                  objectFit={`contain`}
                  priority
                />
              </span>
              <span
                className={`text-base text-gray-900 font-bold mt-0.5 dark:text-gray-100`}
              >
                SEM FOR BUSINESS
              </span>
            </a>
          </Link>

          {/* End */}
          <div className={`flex items-stretch justify-center space-x-8`}>
            <span className={`flex items-center justify-center space-x-3`}>
              {Object.entries(links.desktop).map(
                ([, value]: [string, any], index) => {
                  return (
                    <NavButton
                      key={index}
                      label={value.label}
                      href={value.href}
                      active={pathname == value.href}
                    />
                  );
                }
              )}
            </span>
            <span className={`flex items-center justify-center space-x-3`}>
              {Object.entries(links.distinct).map(
                ([, value]: [string, any], index) => {
                  const Icon =
                    Phosphor[toPhosphorComponentName(value.phosphorId)];
                  return (
                    <NavButton
                      ariaLabel={value.label}
                      key={index}
                      icon={<Icon weight={"regular"} />}
                      label={value.label}
                      type={index === 0 ? "gray" : "blue"}
                      href={value.href}
                    />
                  );
                }
              )}
              <span className={`relative overflow-visible`}>
                <LanguageDropdown />
              </span>
            </span>
          </div>
        </div>
      </div>
    </nav>
  );
}

function Mobile({ className, links }: { className?: string; links: any }) {
  const [isOpen, setIsOpen] = useState(false);
  const navRef = useRef(null);
  const router = useRouter();

  useEffect(() => {
    router.events.on("routeChangeComplete", () => {
      setIsOpen(false);
    });
    return () => {
      router.events.off("routeChangeComplete", () => {});
    };
  }, [router.events]);

  useEffect(() => {
    switch (isOpen) {
      case true:
        document.body.style.overflow = "hidden";
        break;
      case false:
        document.body.style.removeProperty("overflow");
        break;
    }

    return () => {
      // Remove properties on unmount
      document.body.style.removeProperty("overflow");
    };
  }, [isOpen]);

  return (
    <>
      <nav
        className={`${className} fixed flex w-full top-0 inset-x-0 flex-col bg-gray-100 pl-6 pr-4 border-b z-50 dark:border-b-gray-700 dark:bg-gray-700`}
        ref={navRef}
      >
        <div
          className={`container mx-auto flex items-center justify-center z-0 `}
        >
          <div className={`w-full flex items-stretch justify-between`}>
            {/* Start */}
            <Link href={`/`}>
              <a className={`flex items-center justify-center py-3 space-x-2`}>
                <span className={`relative h-9 w-9 shadow rounded`}>
                  <Image
                    alt={`SEM Icon`}
                    src={`/assets/brand/sem-icon.svg`}
                    layout={"fill"}
                    objectFit={`contain`}
                  />
                </span>
                <span
                  className={`text-base text-gray-900 font-bold leading-4 mt-0.5 dark:text-gray-100`}
                >
                  SEM FOR BUSINESS
                </span>
              </a>
            </Link>

            {/* End */}
            <div className={` flex items-stretch justify-center space-x-8`}>
              <span className={`flex items-center justify-center space-x-1`}>
                {Object.entries(links.distinct).map(
                  ([, value]: [string, any], index) => {
                    const Icon =
                      Phosphor[toPhosphorComponentName(value.phosphorId)];
                    return (
                      <NavButton
                        ariaLabel={value.label}
                        key={index}
                        icon={<Icon weight={"regular"} />}
                        type={"gray"}
                        href={value.href}
                      />
                    );
                  }
                )}
                <NavButton
                  icon={
                    isOpen ? (
                      <Phosphor.X weight={`regular`} />
                    ) : (
                      <Phosphor.List weight={`regular`} />
                    )
                  }
                  ariaLabel={"Menu"}
                  type={`gray`}
                  onClick={() => setIsOpen((state) => !state)}
                  active={isOpen}
                />
              </span>
            </div>
          </div>
        </div>
      </nav>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            style={{
              top: navRef.current.clientHeight,
            }}
            className={`fixed inset-0 bg-gray-200 flex flex-col items-stretch justify-start z-40 pt-2`}
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
            transition={{
              duration: 0.15,
            }}
            layout
          >
            {/* Website Navigation */}
            <div
              className={`flex-1 overflow-y-auto flex flex-col items-stretch`}
            >
              <div
                className={`container mx-auto h-full flex flex-col items-stretch justify-start pb-8 px-4`}
              >
                <div
                  className={`flex flex-col items-stretch justify-start divide-y divide-gray-300`}
                >
                  {Object.entries(links.mobile).map(
                    ([, value]: [string, any], index) => {
                      return (
                        <MenuButton
                          ariaLabel={value.label}
                          key={index}
                          label={value.label}
                          href={value.href}
                        />
                      );
                    }
                  )}
                </div>
              </div>
            </div>

            {/* Account button */}
            <div className={"flex"}>
              <a
                aria-label="Coming Soon"
                title="Account"
                href={`/coming-soon`}
                className={`flex-auto h-16 px-6 text-white flex items-center justify-center space-x-1 active:bg-brand-800 bg-brand-500 transition select-none`}
              >
                <span className={`text-2xl flex items-center`}>
                  <Phosphor.UserCircle weight={`regular`} />
                </span>
                <span className={`text-lg font-medium mt-0.5`}>Account</span>
              </a>
              <div>
                <LanguageDropdown />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

function NavButton({
  label,
  icon,
  type = "transparent",
  href,
  onClick,
  ariaLabel,
  active = false,
}: {
  ariaLabel?: string;
  label?: string;
  icon?: ReactElement;
  type?: "blue" | "gray" | "transparent";
  href?: string;
  onClick?: MouseEventHandler;
  active?: boolean;
}) {
  // Style definition
  let style: {
    icon: string;
    container: string;
    text: string;
  };
  switch (type) {
    case "blue":
      style = {
        icon: `text-gray-900 lg:text-white`,
        container: `${
          !active ? "" : `bg-brand-800`
        } active:bg-brand-800 rounded px-2 py-2 lg:py-2 lg:px-4 lg:bg-brand-500 `,
        text: "font-medium text-sm text-white",
      };
      break;
    case "gray":
      style = {
        icon: `${
          !label && !active ? `dark:text-gray-100` : ""
        } text-gray-900 active:text-gray-900`,
        container: `${
          !active ? "" : `bg-gray-300`
        } active:bg-gray-300 rounded px-2 py-2 lg:bg-gray-200 lg:py-2 lg:px-4 `,
        text: `font-medium text-sm text-gray-900`,
      };
      break;
    default:
      style = {
        icon: `text-gray-900 dark:text-gray-100`,
        container: `${
          !active ? "" : "border-b-brand-500 dark:border-b-gray-300"
        } group px-3 flex items-center justify-center border-y-2 border-transparent h-full hover:border-b-brand-500 hover:dark:border-b-gray-300`,
        text: `text-sm text-gray-900 group-hover:text-brand-500 dark:text-gray-100 group-hover:dark:text-gray-300`,
      };
      break;
  }

  const ButtonTag = href ? "a" : "button";
  const button = (
    <ButtonTag
      aria-label={ariaLabel}
      className={`${style.container} transition duration-150 select-none`}
      onClick={onClick}
    >
      <span
        className={`${
          icon && label ? `space-x-1` : ``
        } flex items-center justify-center`}
      >
        {icon && <span className={`${style.icon} text-2xl`}>{icon}</span>}
        {label && <span className={`${style.text}`}>{label}</span>}
      </span>
    </ButtonTag>
  );

  return href ? <Link href={href}>{button}</Link> : <>{button}</>;
}

function MenuButton({
  label,
  type = "transparent",
  href,
  onClick,
  ariaLabel,
  index,
}: {
  ariaLabel?: string;
  label: string;
  icon?: ReactElement;
  type?: "transparent" | "blue";
  href?: string;
  onClick?: MouseEventHandler;
  index?: number;
}) {
  // Style definition
  var style = {
    icon: `text-gray-900 group-active:text-brand-600`,
    container: "group px-3 py-6 flex items-center justify-center",
    text: "text-lg font-medium text-gray-900 group-active:text-brand-600",
  };
  switch (type) {
    case "blue":
      style = {
        icon: `text-white`,
        container:
          "rounded-lg overflow-hidden group px-3 py-3.5 flex items-center justify-center bg-brand-500 active:bg-brand-800",
        text: "text-lg font-medium text-white",
      };
      break;
  }

  const ButtonTag = href ? "a" : "button";
  const button = (
    <ButtonTag
      aria-label={ariaLabel}
      className={`select-none transition ${style.container}`}
      onClick={onClick}
    >
      <span className={`w-full flex items-center justify-between`}>
        <span className={`${style.text}`}>{label}</span>
        <span className={`${style.icon} text-2xl`}>
          <Phosphor.CaretRight weight={`regular`} />
        </span>
      </span>
    </ButtonTag>
  );

  return href ? <Link href={href}>{button}</Link> : <>{button}</>;
}

export default Navbar;
